<template>
  <div class="receipt-container">
    <!-- Header -->
    <div class="receipt-header">
      <h1>Quote</h1>
    </div>

    <!-- Logo -->
    <div class="logo-section">
      <img
        src="../../assets/images/icon/shareLogo.png"
        alt="ISUZU TRUCKS"
        class="isuzu-logo"
      />
    </div>

    <!-- Receipt Content -->
    <div class="receipt-content">
      <CellGroup>
        <Cell title="Date:" :value="orderInfo.createTime" />
        <Cell title="Transaction No.:" :value="orderInfo.id" />
      </CellGroup>

      <div class="section-divider"></div>
      <CellGroup>
        <Cell title="Customer Name:" :value="orderInfo.name" />
        <Cell title="Contact No.:" :value="orderInfo.contact" />
      </CellGroup>

      <div class="section-divider"></div>

      <CellGroup>
        <Cell title="Model:" :value="orderInfo.model" />
        <Cell title="Specification:" :value="orderInfo.specification" />
        <Cell title="Powertrain:" :value="orderInfo.powertrain" />
        <Cell title="Colour:" :value="orderInfo.colour" />
      </CellGroup>

      <div class="section-divider"></div>

      <CellGroup>
        <Cell title="Showroom Price:" :value="`RM ${formateToThousands(orderInfo.price || '0')}`" />
        <Cell title="Campaign Discount:" :value="`RM ${formateToThousands(orderInfo.campaign || '0')}`" />
        <Cell title="Dealer Discount:" :value="`RM ${formateToThousands(orderInfo.dealer || '0')}`" />
      </CellGroup>
      <div class="section-divider"></div>

      <div class="deposit-amount">
        <Cell title="TAX (10%):" :value="`RM ${formateToThousands(orderInfo.tax || '0')}`" />
        <Cell title="Grand Total:" :value="`RM ${formateToThousands(orderInfo.total || '0')}`" />
      </div>

      <div class="company-info">
        <p>Autoexec Corporation Sdn. Bhd., JINJIANG</p>
        <p>200201020113 (587776-U)</p>
        <p>
          Lot 16254E, Jalan Jinjiang Permai, Jinjiang Utara, Kuala Lumpur, 52000
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup } from "vant";
import * as orderApi from "@/api/order";
import dayjs from "dayjs";
import { formateToThousands } from "@/utils/utils.js"

export default {
  name: "Quote",
  components: {
    CellGroup,
    Cell,
  },
  data() {
    return {
      orderInfo: {},
      formateToThousands
    };
  },
  methods: {
    // 获取订单详情
    getOrderDetail() {
      orderApi.shareData({ id: this.$route.query.id }).then((res) => {
        if (res.success) {
          this.orderInfo = Object.assign(JSON.parse(res.data.shareData), {
            id: res.data.id,
            createTime: dayjs(res.data.createtime).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          });
        }
      });
    },
  },
  created() {
    this.getOrderDetail();
  },
};
</script>

<style lang="less" scoped>
.receipt-container {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.receipt-header {
  background-color: #e30613; // ISUZU red color
  color: white;
  padding: 30px;
  text-align: center;

  h1 {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
  }
}

.logo-section {
  padding: 20px 44px;
  text-align: left;

  .isuzu-logo {
    height: 60px;
    object-fit: contain;
  }
}

.receipt-content {
  padding: 0 16px 16px;

  .section-divider {
    height: 8px;
    background-color: #f5f5f5;
    margin: 12px -16px;
  }

  /deep/ .Cell {
    padding: 12px 0;

    &__title {
      color: #666;
      flex: 0 0 140px;
    }

    &__value {
      color: #333;
      font-weight: 500;
    }
  }
}

.deposit-amount {
  margin-top: 16px;

  /deep/ .van-cell__value {
    color: #262626;
    font-weight: bold;
    font-size: 18px;
  }
}

.company-info {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #eee;

  font-size: 20px;
  p {
    margin: 4px 0;
    color: #666;
  }
}
</style>